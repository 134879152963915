import React from 'react';
import Layout from '../layouts/main/Layout';
import Legal from '../components/Legal/Legal';
import NoIndex from '../util/NoIndex/NoIndex';

const legal = ({ ...props }) => {
  const { originalPath } = props.pathContext.intl;
  return (
    <Layout originalPath={originalPath}>
      <NoIndex />

      <Legal />
    </Layout>
  );
};

export default legal;
