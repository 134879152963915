import React from 'react';

const Legal = () => (
  <article className="legal">
    <section className="legal-section">
      <h1>Terms and Conditions</h1>
      <p>Last updated: June 05, 2020</p>
      <p>Please read these terms and conditions carefully before using Our Service.</p>
    </section>
    <section className="legal-section">
      <h2>Interpretation</h2>
      <p>The words of which the initial letter is capitalized have meanings defined under the following conditions.</p>
      <p>The following definitions shall have the same meaning regardless of whether they appear in singular or in plural.</p>
    </section>
    <section className="legal-section">

      <h2>Definitions</h2>
      <p>For the purposes of these Terms and Conditions:</p>
      <ul>
        <li>
          <p>
            <strong>Affiliate</strong>
            {' '}
            means an entity that controls, is controlled by or is under common control with a party, where &quot;control&quot; means ownership of 50% or more of the shares, equity interest or other securities entitled to vote for election of directors or other managing authority.
          </p>
        </li>
        <li>
          <p>
            <strong>Company</strong>
            {' '}
            (referred to as either &quot;the Company&quot;, &quot;We&quot;, &quot;Us&quot; or &quot;Our&quot; in this Agreement) refers to Sugarly Designs.
          </p>
        </li>
        <li>
          <p>
            <strong>Country</strong>
            {' '}
            refers to:  Ireland
          </p>
        </li>
        <li>
          <p>
            <strong>Device</strong>
            {' '}
            means any device that can access the Service such as a computer, a cellphone or a digital tablet.
          </p>
        </li>
        <li>
          <p>
            <strong>Service</strong>
            {' '}
            refers to the Website.
          </p>
        </li>
        <li>
          <p>
            <strong>Terms and Conditions</strong>
            {' '}
            (also referred as &quot;Terms&quot;) mean these Terms and Conditions that form the entire agreement between You and the Company regarding the use of the Service.
          </p>
        </li>
        <li>
          <p>
            <strong>Third-party Social Media Service</strong>
            {' '}
            means any services or content (including data, information, products or services) provided by a third-party that may be displayed, included or made available by the Service.
          </p>
        </li>
        <li>
          <p>
            <strong>Website</strong>
            {' '}
            refers to Sugarly Designs, accessible from
            {' '}
            <a href="https://sugarly.co/" aria-label="Sugarly Designs" rel="external nofollow noopener" target="_blank">https://sugarly.co/</a>
          </p>
        </li>
        <li>
          <p>
            <strong>You</strong>
            {' '}
            means the individual accessing or using the Service, or the company, or other legal entity on behalf of which such individual is accessing or using the Service, as applicable.
          </p>
        </li>
      </ul>
    </section>
    <section className="legal-section">
      <h2>Changes to These Terms and Conditions</h2>
      <p>We reserve the right, at Our sole discretion, to modify or replace these Terms at any time. If a revision is material We will make reasonable efforts to provide at least 30 days' notice prior to any new terms taking effect. What constitutes a material change will be determined at Our sole discretion.</p>
      <p>By continuing to access or use Our Service after those revisions become effective, You agree to be bound by the revised terms. If You do not agree to the new terms, in whole or in part, please stop using the website and the Service.</p>
    </section>
    <section className="legal-section">
      <h1>Contact Us</h1>
      <p>If you have any questions about these Terms and Conditions, You can contact us:</p>
      <ul>
        <li>
          <p>By email: sugarly.designs@gmail.com</p>
        </li>
        <li>
          <p>
            By visiting this page on our website:
            {' '}
            <a href="https://sugarly.co/contact" aria-label="Contact Sugarly Designs" rel="external nofollow noopener" target="_blank">https://sugarly.co/contact</a>
          </p>
        </li>
      </ul>
    </section>

  </article>
);

export default Legal;
