import React from 'react';
import { Helmet } from 'react-helmet';
import { SiteContext } from '../../context/SiteContextProvider';

const NoIndex = () => {
  const defaultLang = 'en';
  const defaultTitle = 'Sugarly Designs – Surface Pattern Designer & Illustrator';
  return (
    <SiteContext.Consumer>
      {(context) => (

        <Helmet
          htmlAttributes={{
            locale: defaultLang,
          }}
          title={defaultTitle}

          meta={[
            {
              name: 'robots',
              content: 'noindex, nofollow, noarchive, nocache, nosnippet',
            },
            {
              name: 'googlebot',
              content: 'noindex, nofollow, noarchive, nocache, nosnippet',
            },
            {
              name: 'googlebot-news',
              content: 'noindex, nofollow, noarchive, nocache, nosnippet',
            },
          ]}
        >
          <script async src="https://www.google-analytics.com/analytics.js" />
          {context.data.cookieConsent
            && (
              <script>
                {`
                window.ga=window.ga||function()
                {(ga.q = ga.q || []).push(arguments)}
                ;ga.l=+new Date; ga('create',
                'UA-166878830-1', 'auto'); ga('send',
                'pageview');
              `}
              </script>
            )}
          {context.data.cookieConsent
            && (
            <script>
              {`
                (function(h,o,t,j,a,r){
                h.hj=h.hj||function(){(h.hj.q=h.hj.q||[]).push(arguments)};
                h._hjSettings={hjid:1814637,hjsv:6};
                a=o.getElementsByTagName('head')[0];
                r=o.createElement('script');r.async=1;
                r.src=t+h._hjSettings.hjid+j+h._hjSettings.hjsv;
                a.appendChild(r);
                })(window,document,'https://static.hotjar.com/c/hotjar-','.js?sv=');
              `}
            </script>
            )}
        </Helmet>
      )}
    </SiteContext.Consumer>
  );
};


export default NoIndex;
